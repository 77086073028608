<template>
  <div>
    <Table
      :actions="actions"
      :items="$store.state.creditPackage.tableData"
      :fields="fields"
      :loading="$store.state.creditPackage.loadingTable"
      :filter="$store.state.creditPackage.tableFilter"
      :sort="$store.state.creditPackage.tableSort"
      :tab-options="tabOptions"
      :sort-options="sortOptions"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Modal
      approvalModalVisible: false,
      rejectionModalVisible: false,
      refundModalVisible: false,

      selectedData: '',

      // Tabs
      tabOptions: [
        { key: 'actives', label: 'All' },
        {
          key: 'actives', label: 'Active', value: true, default: true,
        },
        { key: 'actives', label: 'Inactive', value: false },
      ],

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'storeProductID',
          label: 'Store Product ID',
          visibility: ['view'],
        },
        {
          key: 'amount',
          label: 'Amount',
          type: 'gold',
        },
        // all value is in usd
        {
          key: 'price',
          label: 'Price',
          prefix: '$',
          suffix: ' USD',
        },
        {
          key: 'discount',
          label: 'Discount',
          suffix: '%',
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
        },
        {
          key: 'updatedAt',
          label: 'Updated at',
          type: 'datetime',
          visibility: ['view'],
        },
      ],

      approvalFields: [
        {
          key: 'description',
          label: 'Reason',
          type: 'textarea',
          minLength: 5,
        },
      ],

      actions: [
        'view',
        'update',
        'create',
      ],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('creditPackage/getTableData')
    },
    filterData(val) {
      this.$store.commit('creditPackage/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('creditPackage/SET_TABLE_SORT', val)
    },
  },
}
</script>
